import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search_list = _resolveComponent("search-list");
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_tabs = _resolveComponent("van-tabs");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", {
      'container-PC': _ctx.isPC
    }])
  }, [_ctx.showTabs ? (_openBlock(), _createBlock(_component_van_tabs, {
    key: 0,
    class: "tab",
    active: _ctx.active,
    "onUpdate:active": _cache[0] || (_cache[0] = $event => _ctx.active = $event),
    "title-active-color": "#ff8000",
    border: "",
    onClickTab: _ctx.onTabsClickTab
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      title: "专项练习",
      name: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_search_list, {
        key: "special",
        data: _ctx.list,
        menu: _ctx.SpecialPractice,
        loading: _ctx.loading,
        finished: _ctx.finished,
        onLoad: _ctx.onLoadSearchList,
        onSearch: _ctx.onSearchSpecial
      }, null, 8, ["data", "menu", "loading", "finished", "onLoad", "onSearch"])]),
      _: 1
    }), _createVNode(_component_van_tab, {
      title: "大厂面试",
      name: 2
    }, {
      default: _withCtx(() => [_createVNode(_component_search_list, {
        key: "company",
        data: _ctx.list,
        menu: _ctx.RealQuestion,
        loading: _ctx.loading,
        finished: _ctx.finished,
        onLoad: _ctx.onLoadSearchList,
        onSearch: _ctx.onSearchCompany
      }, null, 8, ["data", "menu", "loading", "finished", "onLoad", "onSearch"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["active", "onClickTab"])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_ctx.active === 1 ? (_openBlock(), _createBlock(_component_search_list, {
    key: "special",
    data: _ctx.list,
    menu: _ctx.SpecialPractice,
    loading: _ctx.loading,
    finished: _ctx.finished,
    onLoad: _ctx.onLoadSearchList,
    onSearch: _ctx.onSearchSpecial
  }, null, 8, ["data", "menu", "loading", "finished", "onLoad", "onSearch"])) : (_openBlock(), _createBlock(_component_search_list, {
    key: "company",
    data: _ctx.list,
    menu: _ctx.RealQuestion,
    loading: _ctx.loading,
    finished: _ctx.finished,
    onLoad: _ctx.onLoadSearchList,
    onSearch: _ctx.onSearchCompany
  }, null, 8, ["data", "menu", "loading", "finished", "onLoad", "onSearch"]))], 64))], 2);
}