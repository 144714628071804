import "core-js/modules/es.array.push.js";
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DropdownMenu, DropdownItem, Button } from 'vant';
import PaperList from '@/components/list.vue';
export default defineComponent({
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Button.name]: Button,
    PaperList
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    menu: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    finished: Boolean
  },
  emits: ['load', 'search', 'reset'],
  setup(props, {
    emit
  }) {
    const isloading = ref(false);
    const isfinished = ref(false);
    const checked = ref([]);
    const router = useRouter();
    // 菜单发生变量后更新菜单已选变量
    watch(() => props.menu, value => {
      checked.value = [...new Array(value.length)].map(() => []);
    }, {
      immediate: true
    });
    // loading 发生变化后更新组件的 paper-list loading 值
    watch(() => props.loading, value => {
      isloading.value = value;
    }, {
      immediate: true
    });
    // finished 发生变化后更新组件 paper-list finished 值
    watch(() => props.finished, value => {
      isfinished.value = value;
    }, {
      immediate: true
    });
    // 关闭菜单
    const closeMenu = () => {
      // 用关闭菜单（ref方案行不通）
      document.querySelectorAll('.van-overlay').forEach(ele => ele.click());
    };
    // 列表加载事件
    const onLoadedList = () => {
      // 触发调用加载事件
      emit('load', checked.value);
    };
    /**
     * 当前选项是否被选中
     * @param number index 当前菜单索引
     * @param number value 当前选项值
     */
    const isChecked = (index, value) => {
      return checked.value[index].includes(value);
    };
    /**
     * 选项点选事件
     * @param number index 当前菜单索引
     * @param number value 当前选项值
     */
    const onCheckOption = (index, value) => {
      const arr = checked.value[index];
      if (isChecked(index, value)) {
        arr.splice(arr.indexOf(value), 1);
      } else {
        arr.push(value);
      }
    };
    // 菜单确定选择
    const onSubmitMenu = () => {
      // 参数去重
      emit('search', checked.value);
      // 用关闭菜单（ref方案行不通）
      closeMenu();
    };
    // 重置筛选
    const onResetMenu = index => {
      checked.value[index] = [];
    };
    // 列表点击事件
    const onTapItem = id => {
      router.push(`/paper/${id}`);
    };
    return {
      isloading,
      isfinished,
      checked,
      onLoadedList,
      onSubmitMenu,
      onResetMenu,
      isChecked,
      onCheckOption,
      onTapItem
    };
  }
});