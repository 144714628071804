import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Tab, Tabs, Toast } from 'vant';
import SearchList from '@/components/views/index/search_list.vue';
import { DIFFICULTY } from '@/constants/common';
import { dispatchAppFeature, getPlatform } from '@/utils/util';
let IndexVue = class IndexVue extends Vue {
  constructor() {
    super(...arguments);
    this.pageIndex = 0;
    this.pageSize = 10;
    this.keywords = '';
    this.loading = true;
    this.finished = false;
    this.request = false;
    this.showTabs = true;
    // 搜索条件
    this.condition = {};
    // 专项练习
    this.SpecialPractice = [];
    // 公司真题
    this.RealQuestion = [];
    this.active = 1;
  }
  get isPC() {
    return getPlatform().isPC;
  }
  async created() {
    // 获取参数
    const {
      type
    } = this.$route.query;
    this.active = parseInt(type);
    this.updateNavigateForApp(type);
    // 默认加载第一个菜单
    await this.getMenu(parseInt(type) || 1);
    // 默认加载数据
    await this.onLoadSearchList();
  }
  updateNavigateForApp(type) {
    if (type) {
      dispatchAppFeature('initWebViewUIConfigParams', {
        title: ['专项练习', '大厂面试'][~~type - 1],
        show: true
      });
      this.showTabs = false;
    }
  }
  async onTabsClickTab({
    name
  }) {
    // 加载菜单
    await this.getMenu(~~name);
    this.clear();
    // 查询数据
    this.pageIndex = 0;
    this.condition = {};
    this.finished = false;
    this.request = false;
    await this.onLoadSearchList();
  }
  // 获取指定条件下的菜单
  async getMenu(type = 1) {
    // 重置搜索条件
    this.condition = {};
    // 如果菜单已经加载过，则不再加载
    if (type === 1 && this.SpecialPractice.length || type === 2 && this.RealQuestion.length) {
      return;
    }
    if (type === 1) {
      // 加载专项练习
      this.loadedSpecialQuestion();
    } else {
      // 加载公司真题菜单
      this.loadedCompanyQuestion();
    }
  }
  // 加载专项练习
  async loadedSpecialQuestion() {
    const r = await this.getDiscipline();
    this.SpecialPractice = [{
      name: '全部专项',
      options: r.data.map(item => ({
        text: item.name,
        value: item.id
      }))
    }, {
      name: '考卷难度',
      options: DIFFICULTY
    }];
  }
  // 加载公司真题菜单
  async loadedCompanyQuestion() {
    const param = {
      data: {},
      pageIndex: 1,
      pageSize: 50
    };
    const [company, position] = await Promise.allSettled([this.getMenuByTypeId({
      ...param,
      data: {
        typeId: 2
      }
    }), this.getMenuByTypeId({
      ...param,
      data: {
        typeId: 3
      }
    })]);
    this.RealQuestion = [{
      name: '全部公司',
      options: company.value.data.data.map(item => ({
        text: item.valueName,
        value: item.id
      }))
    }, {
      name: '适合职位',
      options: position.value.data.data.map(item => ({
        text: item.valueName,
        value: item.id
      }))
    }, {
      name: '考卷难度',
      options: DIFFICULTY
    }];
  }
  // 加载指定条件下的题库列表
  async onLoadSearchList() {
    if (this.request || this.finished) {
      return;
    }
    this.request = true;
    this.loading = true;
    const {
      code,
      data: {
        resultCount
      },
      msg
    } = await this.getQuestionPageList({
      data: {
        type: this.active === 1 ? 1 : 0,
        ...this.condition
      },
      pageIndex: ++this.pageIndex,
      pageSize: this.pageSize
    });
    this.request = false;
    this.loading = false;
    if (code === 0) {
      // 判断是否已经全部加载完毕
      if (this.pageIndex * this.pageSize >= resultCount) {
        this.finished = true;
      }
    } else {
      this.finished = true;
      Toast({
        message: msg || '数据查询失败'
      });
    }
  }
  // 专项练习搜索
  onSearchSpecial(data) {
    this.pageIndex = 0;
    this.finished = false;
    this.condition = {
      subIds: data[0].join(','),
      difficultys: data[1].join(',')
    };
    this.onLoadSearchList();
  }
  // 公司真题搜索
  onSearchCompany(data) {
    this.pageIndex = 0;
    this.finished = false;
    this.condition = {
      quizCompanyId: data[0].join(','),
      quizPositionId: data[1].join(','),
      difficultys: data[2].join(',')
    };
    this.onLoadSearchList();
  }
};
__decorate([Getter(`Index/list`)], IndexVue.prototype, "list", void 0);
__decorate([Action(`Index/getQuestionPageList`)], IndexVue.prototype, "getQuestionPageList", void 0);
__decorate([Action(`Index/getMenuByTypeId`)], IndexVue.prototype, "getMenuByTypeId", void 0);
__decorate([Action(`Index/getDiscipline`)], IndexVue.prototype, "getDiscipline", void 0);
__decorate([Action(`Index/clear`)], IndexVue.prototype, "clear", void 0);
IndexVue = __decorate([Options({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    SearchList
  }
})], IndexVue);
export default IndexVue;