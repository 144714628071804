import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-334d0ca4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search-container"
};
const _hoisted_2 = {
  class: "nav"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "btns"
};
const _hoisted_5 = {
  class: "list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item");
  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu");
  const _component_paper_list = _resolveComponent("paper-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_dropdown_menu, {
    "active-color": "#ff8000"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, index) => {
      return _openBlock(), _createBlock(_component_van_dropdown_item, {
        key: index,
        title: item.name
      }, {
        default: _withCtx(() => [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (n, i) => {
          return _openBlock(), _createElementBlock("li", {
            key: i,
            class: _normalizeClass({
              on: _ctx.isChecked(index, n.value)
            }),
            onClick: $event => _ctx.onCheckOption(index, n.value)
          }, _toDisplayString(n.text), 11, _hoisted_3);
        }), 128))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
          round: "",
          class: "btn b1",
          onClick: $event => _ctx.onResetMenu(index)
        }, {
          default: _withCtx(() => [_createTextVNode("重置")]),
          _: 2
        }, 1032, ["onClick"]), _createVNode(_component_van_button, {
          round: "",
          class: "btn b2",
          onClick: _ctx.onSubmitMenu
        }, {
          default: _withCtx(() => [_createTextVNode("确定")]),
          _: 1
        }, 8, ["onClick"])])]),
        _: 2
      }, 1032, ["title"]);
    }), 128))]),
    _: 1
  })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_paper_list, {
    data: _ctx.data,
    loading: _ctx.isloading,
    finished: _ctx.isfinished,
    onLoad: _ctx.onLoadedList,
    onClickItem: _ctx.onTapItem
  }, null, 8, ["data", "loading", "finished", "onLoad", "onClickItem"])])]);
}